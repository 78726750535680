.dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: 2px dashed #1db954;
    border-radius: 5px;
    margin: .2rem auto;
    color: #cce2e6;
}

.dropzone-all {
    width: 50%;
    color: #cce2e6;
    margin: 0 auto 5rem;
}

@media (orientation: portrait) {
    .dropzone-all {
        width: 90%;
    }
}

.dropzone-all ul {
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0;
    gap: .5rem;
    margin: .5rem auto;
}

.dropzone-error {
    color: #e24646;
    margin: .5rem 0;
}

.dropzone-info {
    font-size: .8rem;
}

.dropzone-item {
    border: .1rem solid #cce2e6;
    border-radius: .25rem;
    padding: .5rem;
}

.dropzone-item-remove {
    background-color: #e24646;
    color: #1a1e1f;
    cursor: pointer;
    position: absolute;
    top: .1rem;
    right: .1rem;
    border: .1rem solid #1a1e1f;
    border-radius: 50%;
    width: .6rem;
    height: .6rem;
    font-size: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropzone-submit {
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
}

.dropdown-submit-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.loading-acct {
    color: #3de2ff;
    width: 100%;
    text-align: center;
}

.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.popup-content {
    height: 85%;
    overflow-y: auto;
    background-color: #2a2e2f;
    padding: 1rem;
    border-radius: .5rem;
    color: #cce2e6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 40%;
}

@media (orientation: portrait) {
    .popup-content {
        width: 90%;
    }
}

.popup-content h1 {
    margin: 0 auto 1rem;
}

.popup-ok {
    margin: .5rem auto 0 !important;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}

.table-acct {
    color: #cce2e6;
    margin: 2rem auto;
    width: 50%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

@media (orientation: portrait) {
    .table-acct {
        width: 90%;
        font-size: 1rem;
    }
}

.table-row-acct {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;
}

.whats-this {
    cursor: pointer;
}
