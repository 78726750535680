.connection-modal {
    position: fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    padding: 1rem;
    border: #cce2e6 .1rem solid;
    border-radius: .5rem;
    color: #cce2e6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.connection-modal-circle {
    height: .3rem;
    width: .3rem;
    border-radius: 50%;
    margin-right: .7rem;
}

.connection-modal-connected {
    background-color: #1db954;
    box-shadow: 0 0 .5rem .2rem #1db954;
}

.connection-modal-disconnected {
    background-color: #e24646;
    width: .5rem !important;
    height: .5rem !important;
}

.custom-link {
    color: #3de2ff;
    text-decoration: none;
}

.default-text-color {
    color: #3de2ff;
}

.footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    padding: 0.7rem;
    font-size: 1rem;
}

.hamburger {
    position: relative;
    display: inline-block;
}

.hamburger-button {
    height: 2rem;
    width: 2rem;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;
}

.hamburger-button-line {
    height: 0.2rem;
    width: 2rem;
    background-color: #3de2ff;
    border-radius: 0.2rem;
}

.hamburger-menu {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0.5rem;
    background-color: #1a1e1f;
    box-shadow: 0 .06rem .3rem rgba(0, 0, 0, 0.1);
    border: .1rem solid #3de2ff;
    border-radius: 0.25rem;
    z-index: 1;
}

.hamburger-menu li {
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 0.2rem;
}

.hamburger-menu li:hover {
    background-color: #b3c9cc;
    color: #1a1e1f;
}

.hamburger-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.header-all {
    z-index: 1;
    top: 0;
    left: 0;
    padding: 0.65rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.login-button {
    background-color: #1db954;
    padding: .6rem;
    border-radius: .6rem;
    display: inline-block;
    color: #1a1e1f;
    margin: .6rem;
    text-align: center;
}

.login-button:hover {
    background-color: #cce2e6;
    color: #1a1e1f;
    cursor: pointer;
}

.login-button-wrapper {
    width: 100%;
    text-align: center;
}

.logo-primary {
    height: 3rem;
}

@media (orientation: portrait) {
    .logo-primary {
        height: 2rem;
    }
}

.logo-primary:hover {
    cursor: pointer;
}

.nav-button {
    padding: .65rem 1.3rem;
    border-radius: .65rem;
}

.nav-button:hover {
    background-color: #3de2ff;
    color: #1a1e1f;
    cursor: pointer;
    transition: .8s;
}

.nav-buttons {
    display: flex;
    flex-direction: row;
    padding: .65rem;
}

.primary-info {
    text-align: center;
    font-size: 4rem;
    color: #cce2e6;
    margin: 1.5rem;
}

@media (orientation: portrait) {
    .primary-info {
        font-size: 2.5rem;
    }
}

.screenshot {
    width: 80%;
    margin-top: 1rem;
    border-radius: .2rem;
}

.secondary-info {
    text-align: center;
    font-size: 1.9rem;
    color: #cce2e6;
    margin: 1.5rem;
}

.test-border {
    border: red .1rem solid;
}

.text-color-white {
    color: #cce2e6;
}