body, html {
  position: relative;
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  width: 100%;
  font-family: "Arial", sans-serif;
  margin: 0;
  background-image: linear-gradient(#1a1e1f, black);
  background-repeat: no-repeat;
  background-size: 100%;
}