.all-panels {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 50%;
    border-radius: .5rem;
    border: .1rem solid #cce2e6;
}

@media (orientation: portrait) {
    .all-panels {
        width: 70%;
    }
}

.corner-rounded-left {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-left: .1rem solid #cce2e6;
}

.corner-rounded-right {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border-right: .1rem solid #cce2e6;
}

.custom-time {
    position: relative;
    display: flex;
    align-items: center;
    color: #cce2e6;
}

.custom-time-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: .6rem;
}

.decade-wrapper {
    padding: .7rem;
    width: 15rem;
}

@media (orientation: portrait) {
    .decade-wrapper {
        width: 13rem;
    }
}

.disclaimer {
    text-align: left;
    font-size: .8rem;
    color: #3de2ff;
    margin: 0 auto 5rem;
    width: 50%;
    padding: .4rem;
}

@media (orientation: portrait) {
    .disclaimer {
        width: 70%;
    }
}

.dropdown-stats {
    position: relative;
    display: inline-block;
    margin: .6rem auto 5rem;
}

@media (orientation: portrait) {
    .dropdown-stats {
        margin: .6rem auto 5rem;
    }
}

.dropdown-stats-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #cce2e6;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 12rem;
}

.dropdown-stats-menu {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 0.5rem;
    background-color: #cce2e6;
    box-shadow: 0 .06rem .3rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    z-index: 1;
}

.dropdown-stats-menu li {
    padding: 0.5rem;
    cursor: pointer;
}

.dropdown-stats-menu li:hover {
    background-color: #b3c9cc;
}

.dropdown-stats-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown-time {
    position: relative;
    display: inline-block;
    margin: .6rem auto;
}

.dropdown-time-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    background-color: #cce2e6;
    border-radius: 0.25rem;
    cursor: pointer;
    width: 12rem;
}

.dropdown-time-menu {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0.5rem;
    background-color: #cce2e6;
    box-shadow: 0 .06rem .3rem rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    z-index: 1;
}

.dropdown-time-menu li {
    padding: 0.5rem;
    cursor: pointer;
}

.dropdown-time-menu li:hover {
    background-color: #b3c9cc;
}

.dropdown-time-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dd-wrapper {
    width: 100%;
    text-align: center;
}

.extra-bottom-margin {
    margin-bottom: 1.5rem !important;
}

.hour-wrapper {
    padding: .7rem;
    width: 25rem;
}

@media (orientation: portrait) {
    .hour-wrapper {
        width: 15rem;
    }
}

.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    width: 100%;
}

.panel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: #cce2e6;
    border-bottom: .1rem solid #cce2e6;
    width: 100%;
}

@media (orientation: portrait) {
    .panel {
        flex-direction: column;
    }
}

.panel-commentary {
    font-size: .8rem;
}

.panel-data {
    font-size: 3rem;
    color: #3de2ff;
}

.panel-last {
    border-bottom: none !important;
}

.panel-primary {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    padding: .5rem;
    font-size: 2rem;
    text-align: left;
}

@media (orientation: portrait) {
    .panel-primary {
        align-items: center;
        text-align: center;
        padding-bottom: 0;
    }
}

.panel-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    padding: .5rem;
    text-align: right;
}

@media (orientation: portrait) {
    .panel-right {
        align-items: center;
        text-align: center;
    }
}

.pie-chart-wrapper {
    margin: 0 auto;
    width: 30rem;
    height: 30rem;
}

@media (orientation: portrait) {
    .pie-chart-wrapper {
        width: 90%;
    }
}

.selector {
    display: flex;
    cursor: pointer;
    margin: 0 auto .5rem;
    width: 25rem;
    justify-content: center;
}

@media (orientation: portrait) {
    .selector {
        width: 85%;
    }
}

.selector-option {
    padding: .5rem;
    width: 25%;
    border-top: .1rem solid #cce2e6;
    border-bottom: .1rem solid #cce2e6;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.selector-selected {
    background-color: #cce2e6;
    color: #1a1e1f;
}

.selector-unselected {
    background-color: #1a1e1f;
    color: #cce2e6;
}

.small-description {
    font-size: .8rem;
    color: #3de2ff;
    margin: 0 auto;
    text-align: center;
}

.submit-times {
    position: absolute;
    left: 100%;
    color: #1a1e1f;
    background-color: #1db954;
    cursor: pointer;
    padding: .1rem .5rem;
    border-radius: .3rem;
    text-align: center;
}

.submit-times:hover {
    background-color: #cce2e6;
}

.table-all {
    color: #cce2e6;
    width: 80%;
    margin: 0 auto 1rem;
    border-collapse: collapse;
}

@media (orientation: portrait) {
    .table-all {
        width: 95%;
    }
}

.table-all-artist {
    width: 40rem !important;
}

@media (orientation: portrait) {
    .table-all-artist {
        width: 90% !important;
    }
}

.table-column-names {
    color: #3de2ff;
}

.table-column-names th {
    text-align: left;
    padding: .62rem .5rem;
    border-bottom: .06rem solid #555555;
}

.table-link {
    color: #cce2e6;
    text-decoration: none;
}

.table-row {
    border-bottom: .06rem solid #555555;
}

.table-row td {
    padding: .62rem .5rem;
}

@media (orientation: portrait) {
    .table-row td {
        padding: .62rem .25rem;
    }
}

.temp-message {
    color: #cce2e6;
    font-size: 1.5rem;
    text-align: center;
    margin: 0 auto;
    padding: 10rem;
}

@media (orientation: portrait) {
    .temp-message {
        padding: 5rem;
    }
}

.time-dd-username {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
}

@media (orientation: portrait) {
    .time-dd-username {
        width: 95%;
    }
}

.time-input {
    width: 100%;
    text-align: center;
    border: .1rem solid #cce2e6;
    border-radius: .25rem;
    background-color: #1a1e1f;
    color: #cce2e6;
    font-size: 1rem;
}

.time-input-button {
    width: 5rem;
    margin: 0 .5rem;
    text-align: center;
    border: .1rem solid #cce2e6;
    border-radius: .25rem;
    background-color: #1a1e1f;
    color: #cce2e6;
    font-size: 1rem;
    cursor: pointer;
}

.time-input-button-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 100%;
    top: 0;
}

.time-input-wrapper {
    width: 7rem;
    margin: 0 .5rem;
    display: flex;
}

.time-inputs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.time-input-wrapper > div > div.react-datepicker__input-container,
.time-input-wrapper > div > div.react-datepicker__input-container input {
    display: flex;
    width: 100%;
}

.user-agnostic-switch {
    color: #3de2ff;
    text-decoration: underline;
    cursor: pointer;
    width: 10rem;
    text-align: right;
}