.landing-image {
    width: 100%;
    border-bottom: #cce2e6 .12rem solid;
}

.landing-info {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1.2rem 1.2rem;
    /*height: 27rem;*/
}

@media (orientation: portrait) {
    .landing-info {
        margin-bottom: 5rem;
    }
}

.landing-tile {
    display: flex;
    flex-direction: column;
    margin: 1.2rem 2.4rem;
    border: .12rem solid #cce2e6;
    border-radius: .3rem;
    font-size: 1.4rem;
    width: 15rem;
    height: 22rem;
}

.landing-tile:hover {
    cursor: pointer;
    width: 16.5rem;
    height: 24.2rem;
    font-size: 1.54rem;
    transition: .5s;
}

.landing-tile-text {
    display: flex;
    flex-direction: column;
    color: #cce2e6;
    padding: .65rem;
    justify-content: center;
    text-align: center;
    height: 7rem;
}