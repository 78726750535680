.terms-list {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: .65rem auto 4rem;
    font-size: 2rem;
    text-align: left;
}

@media (orientation: portrait) {
    .terms-list {
        width: 90%;
        font-size: 1.3rem;
    }
}

.terms-list p {
    text-align: center;
}

.terms-list-item {
    font-size: 1.3rem;
    color: #cce2e6;
}